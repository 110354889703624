<template>
  <vx-card title="Apply DN">
    <!-- <vs-tabs style="border-size"> -->
      <!-- <vs-tab label="DN Trade"> -->
        <trade></trade>
      <!-- </vs-tab> -->
    <!-- </vs-tabs> -->
  </vx-card>
</template>
<script>
import Trade from "./trade/index.vue";
export default {
  components: {
    Trade,
  },
};
</script>
<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide
      ]"
    >
      <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="5%"></vs-th>
          <vs-th>Supplier</vs-th>
          <vs-th sort-key="dn_code">DN Number</vs-th>
          <vs-th sort-key="po_code">PO Number</vs-th>
          <vs-th sort-key="received_date">Received Date</vs-th>
          <vs-th>Warehouse</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Show">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(tr, indextr, data[indextr].remaining_dn_value)
                    "
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </template>
              <!-- <template v-if="data[indextr].total_dn == data[indextr].remaining_dn_value">
                <vx-tooltip text="Reversal">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    color="red"
                    icon="icon-corner-up-left"
                    @click.stop="
                      handleReversal(
                        data[indextr].dn_id
                      )
                    "
                  />
                </vx-tooltip>
              </template> -->
            </vs-td>
            <vs-td :data="data[indextr].supplier_name">
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_code }} -
                  {{ data[indextr].supplier_name }}</span
                >
              </div>
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_address }},
                  {{ data[indextr].supplier_city }} ({{
                    data[indextr].supplier_contact_name
                  }}
                  - {{ data[indextr].supplier_phone }})</span
                >
              </div>
            </vs-td>
            <vs-td>
              <!-- {{ data[indextr].po_code ? data[indextr].dn_code : "-" }} -->
              DN Number : {{ data[indextr].dn_code }} <br />
              Type : {{ data[indextr].type }}<br />
              SCN Number :
              {{ data[indextr].cn_number ? data[indextr].cn_number : "-"
              }}<br />
              SR Number :
              {{ data[indextr].sr_number ? data[indextr].sr_number : "-"
              }}<br />
              <br />
              Total Value :
              {{
                data[indextr].total_dn
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </vs-td>
            <vs-td>
              {{ data[indextr].po_code ? data[indextr].po_code : "-" }}
            </vs-td>
            <vs-td :data="data[indextr].received_date">
              {{
                data[indextr].received_date ? data[indextr].received_date : "-"
              }}
            </vs-td>
            <vs-td :data="data[indextr].warehouse_code">
              {{ data[indextr].warehouse_code }} <br />
              {{ data[indextr].warehouse_name }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <!-- form component -->
          <detail
            @close="handleClose"
            :dn_id="dn_id"
            :dn_code="dn_code"
            :cn_number="cn_number"
            :cn_date="cn_date"
            :tr_number="tr_number"
            :tr_date="tr_date"
            :remaining_dn_value="remaining_dn_value"
            :notes="notes"
            :dn_type="dn_type"
            :supplier_id="supplier_id"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import Detail from "./detail.vue";
export default {
  components: {
    Detail
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0
      },
      dn_id: "",
      dn_type: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      notes: "",
      supplier_id: "",
      remaining_dn_value: 0,
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: 1,
            type: "apply-dn"
          }
        })
        .then(resp => {
          console.log(resp);
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    handleReversal(id) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/reversal/" + id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
          this.getData();
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(data, index, remaining) {
      this.dn_id = data.dn_id;
      this.notes = data.notes;
      this.dn_code = data.dn_code;
      this.cn_number = data.cn_number;
      this.cn_date = data.cn_date == "0001-01-01" ? "" : data.cn_date;
      this.tr_number = data.tr_number;
      this.tr_date = data.tr_date == "0001-01-01" ? "" : data.tr_date;
      this.remaining_dn_value = remaining;
      this.dn_type = data.type;
      console.log(data, "cari supplier id");
      this.supplier_id = data.supplier.ID
      this.table.data = [];
      this.table.data = this.data;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    }
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>

<template>
  <div>
    <vs-tabs>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
      <vs-tab label="Applied">
        <div class="tab-text">
          <applied></applied>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
      
    </vs-tabs>
  </div>
</template>
<script>
import Approved from "./approved";
import Applied from "./applied";
import Reversed from "./reversed";
// import Reversed from '../../payment_for_dn/reversed/reversed.vue';
// import Trade from "./trade/index.vue";
export default {
  components: {
    Approved,
    Applied,
    Reversed
    // Trade,
  },
};
</script>
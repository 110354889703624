<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%;"><b>{{proposal.Code}}</b></vs-divider>
            Code Invoice : {{proposal.PurchaseInvoiceCode}}<br>
            <template v-if="getCurrencyCode(proposal.PurchaseInvoiceCode)[0].purchaseInvoice.CurrencyCode != 'IDR'">
              Currency : {{ getCurrencyCode(proposal.PurchaseInvoiceCode)[0].purchaseInvoice.CurrencyCode }} <br>
              Exchange Rate : {{priceFormat(paymentRequest.CurrencyExchangeRate)}} <br>
            </template>
            Paid By Debit Note : {{priceFormat(proposal.PaidValue)}}<br>
            

          </div>
        </template>
        <div> Reversal Posting Date : {{dateFormat(this.reversalDate)}} <br>  </div>
        <vs-divider style="width: 100%;"></vs-divider>
      </div>
      
      
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
      selected: Object,
  },
  methods: {
    initialState() {
      return {
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        purchaseInvoice: [],
        paymentRequest: {},
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        reversalDate:'',
        notes:[],
        Newnote:"",
      };
    },
    handleReversal() {
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 8,
        reference_code:this.reference_code,
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/change-status',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http.get('/api/v1/purchase-payment-proposal',{
            params: {
                status: 1,
                request_code: this.selected.Code,
                search: query,
                length: 100,
                still_empty: 1,
                // page: params.page,
                // order: params.sort,
                // sort: params.order,
                supplier_code: this.supplier.Code
            }
        }).then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal
          //console.log(r)
        }).catch((e) => {
          //console.log(e)
        })
    },
    getOptionBankOperationUnit() {
      this.$http.get('/api/v1/purchase-payment-proposal/bank-operating-unit').then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank
          this.bank = r.data.dataBank
          console.log()
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map(v => {
              if (v.BankID == this.selected.BankID && v.AccountNumber == this.selected.AccountNumber) {
                  this.operatingUnitBank = v
              }
            }) 
          }
        }).catch((e) => {
          //console.log(e)
        })
    },
    getCurrencyCode(code) {
      var invoices =  this.purchaseInvoice.filter(v => {
        if (v.purchaseInvoice.Code == code) {
          return v.purchaseInvoice
        }
      })
      return invoices
    },
    getOptionSupplierBank() {
      this.$http.get('/api/v1/pi/supplier-account/' + this.supplier.ID).then((r) => {
        this.optionBankSupplier = r.data.account
        //console.log(this.selected)
        if (this.selected.ID) {
          r.data.account.map(v => {
            if (v.BankName == this.selected.ToBankName && v.AccountNumber == this.selected.ToAccountNumber) {
                  this.supplierBank = v
            }
          }) 
        }
        //console.log(r)
      }).catch((e) => {
        //console.log(e)
      })

    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
        .then(result => {
          // this.$vs.loading.close();
          this.paymentProposal = []
          this.paymentRequest = result.data.purchasePaymentRequest
          this.purchaseInvoice = result.data.purchaseInvoice
          result.data.purchasePaymentProposal.map( v => {
            this.paymentProposal.push(v)
          })
          this.notes = this.selected.Notes.toString().split(";")
          //console.log(result)
        })
        .catch(e => {
          this.$vs.loading.close();

        })
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map(v => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v
                }
              })
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    // console.log("mounted", this.selected)
    this.getOptionBankOperationUnit();
    this.getData()
    this.getOptionSupplier(this.selected.SupplierName)
    // this.reversalDate = moment()
    this.reversalDate = this.selected.ReversalDate
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {},
  watch: {
    "supplier.ID"(){ 
      this.getOptionSupplierBank()
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData()
      this.getOptionSupplier(this.selected.SupplierName)
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>